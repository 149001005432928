import React from 'react';
import { graphql } from 'gatsby';
import CardGrid from '../../../components/common/cardgrid/CardGrid';
import { PageProps } from '../types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Styled from './ServiceListPage.styled';
import LabeledDropdown from '../../../components/common/dropdown/LabeledDropdown';
import { SortOrder } from '../../../hooks/useSortedData';
import {
  containsString,
  FilterFunc,
  isDateAvailableWithinDateRange,
  matchesAny,
} from '../../../hooks/useFilteredData';
import useContentfulTransformedCardGridData from '../../../hooks/useContentfulTransformedCardGridData';
import { CardType } from '../../../components/common/card/Card';
import CardGridHeader from '../../../components/common/cardgrid/CardGridHeader';
import { MultiSelectDropdownProps } from '../../../components/common/dropdown/MultiSelectDropdown';
import useUrlParamAwareState from '../../../hooks/useUrlParamAwareState';
import { SearchPageGrid } from '../../../components/common/layout/Grid.styled';
import { ContentfulGatsbyImage } from '../../../types/images';
import SearchFilters from '../../../components/common/list-filters/SearchFilters';
import { localize } from '../../../localization/i18n';
import { isDomAvailable } from '../../../utils/env-utils';
import useActivityData, {
  ActivityFieldsRaw,
} from '../../../hooks/useActivityData';
import { DateRange, isValidDateRange } from '../../../utils/date-utils';
import { dateParamsToDateRange, encodeDateRangeParams } from '../utils';
import AvailabilitySearchForm from '../../../components/common/list-filters/AvailabilitySearchForm';
import usePagedData from '@Levi/hooks/usePagedData';
import { TertiaryButton } from '@Levi/components/common/button/Button';
import Icon from '@Levi/components/common/icon/Icon';
import { Icons } from '@Levi/types/icon-types';
import { startOfToday } from 'date-fns';

export type ActivityFields = {
  id: string;
  activityId: string;
  title: string;
  description?: string;
  price: {
    amount: number;
    currency: string;
  };
  image: {
    childImageSharp: ContentfulGatsbyImage;
  };
  categories: [string];
  bookingStatus?: Date[];
};

type ActivityCategoryFields = {
  id: string; // activities have these ids inside "categories" -array
  title: string; // localized label
};

type SearchActivitiesPageProps = PageProps & {
  data: {
    activities: {
      edges: Array<{ node: ActivityFieldsRaw }>;
    };
    categories: {
      edges: Array<{ node: ActivityCategoryFields }>;
    };
  };
};

const seasonCategoryIds = ['30040', '72919'];

const defaultSortOrder = 'desc'; 

const SearchActivitiesPage: React.FC<SearchActivitiesPageProps> = ({
  data: {
    activities: { edges: cards },
    categories,
  },
  location,
}) => {
  const { seasonalCategories, activityCategories } = React.useMemo(
    () =>
      categories.edges.reduce<{
        seasonalCategories: ActivityCategoryFields[];
        activityCategories: ActivityCategoryFields[];
      }>(
        (acc, curr) => {
          if (seasonCategoryIds.includes(String(curr.node.id))) {
            acc.seasonalCategories.push(curr.node);
          } else {
            acc.activityCategories.push(curr.node);
          }
          return acc;
        },
        {
          seasonalCategories: [],
          activityCategories: [],
        },
      ),
    [categories],
  );

  const data = useContentfulTransformedCardGridData({
    cards: useActivityData(cards),
    type: CardType.Activity,
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const [dates, setDates] = useUrlParamAwareState({
    location,
    paramName: 'dates',
    initialValue: [],
  });

  const [selectedSeasons, setSelectedSeasons] = useUrlParamAwareState({
    location,
    paramName: 'season',
    initialValue: [seasonCategoryIds[0]],
  });

  const [selectedCategories, setSelectedCategories] = useUrlParamAwareState({
    location,
    paramName: 'category',
    initialValue: [],
  });

  const filters = React.useMemo<FilterFunc[]>(() => {
    const defaultFilters = [
      containsString('title', searchTerm),
      matchesAny('categories', selectedSeasons),
      matchesAny('categories', selectedCategories),
    ];
    const dateRange = dateParamsToDateRange(dates);
    if (dateRange && isValidDateRange(dateRange)) {
      return [
        ...defaultFilters,
        isDateAvailableWithinDateRange('bookingStatus', dateRange),
      ];
    }
    return defaultFilters;
  }, [dates, searchTerm, selectedCategories, selectedSeasons]);

  const { dataSlice, reSort, maxItems, fetchMore, hasMore } = usePagedData({
    data: isDomAvailable() ? data : [],
    sortBy        : "title",
    initialSortOrder: defaultSortOrder,
    filters,
    initialOffset : 0,
    itemLimit     : 18 // Data is in up to 3 columns, select number that's divisible by 2 or 3
  });

  const listFilters = React.useMemo<MultiSelectDropdownProps[]>(
    () => [
      {
        name: 'seasonsFilter',
        label: localize('filters.listFilters.season'),
        items: seasonalCategories.map(({ id, title }) => ({
          value: String(id),
          label: title,
        })),
        onChange: setSelectedSeasons,
        value: selectedSeasons,
        variant: 'primary',
      },
      {
        name: 'categoryFilter',
        label: localize('filters.listFilters.category'),
        items: activityCategories.map(({ id, title }) => ({
          value: String(id),
          label: title,
        })),
        onChange: setSelectedCategories,
        value: selectedCategories,
        variant: 'primary',
      },
    ],
    [
      activityCategories,
      seasonalCategories,
      selectedCategories,
      selectedSeasons,
      setSelectedCategories,
      setSelectedSeasons,
    ],
  );

  return (
    <main>
      <Styled.ContentContainer>
        <SearchPageGrid noAside>
          <Styled.MainSection>
            <Typography.Display>
              {localize('activities.headline')}
            </Typography.Display>

            <Styled.PrimaryFilterDateOnly>
              <AvailabilitySearchForm
                minDate={startOfToday()}
                selectedRange={
                  dateParamsToDateRange(dates) as
                    | Required<DateRange>
                    | undefined
                }
                onSelectedDateRangeChange={(range) =>
                  range && isValidDateRange(range)
                    ? setDates([encodeDateRangeParams(range)])
                    : setDates([])
                }
              />
            </Styled.PrimaryFilterDateOnly>

            <SearchFilters
              filters={listFilters}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />

            <CardGridHeader
              title={localize('searchResults.seachResultsTitle')}
              count={maxItems}
            >
              <LabeledDropdown
                variant="secondary"
                defaultValue={defaultSortOrder}
                name="sortBy"
                dropdownLabel={localize('searchResults.sort.label')}
                label=""
                onChange={(value) => reSort(value as SortOrder)}
                items={[
                  {
                    value: 'asc',
                    label: localize('searchResults.sort.textAsc'),
                  },
                  {
                    value: 'desc',
                    label: localize('searchResults.sort.textDesc'),
                  },
                ]}
              />
            </CardGridHeader>
            <CardGrid
              cards={dataSlice}
              columns={3}
              mobileScrollable={false}
            />
            {hasMore && (
              <Styled.LoadMoreButtonContainer>
                <TertiaryButton onClick={fetchMore}>
                  {localize('searchResults.loadMore')}{' '}
                  <Icon type={Icons.ChevronDownLarge} />
                </TertiaryButton>
              </Styled.LoadMoreButtonContainer>
            )}
          </Styled.MainSection>
        </SearchPageGrid>
      </Styled.ContentContainer>
    </main>
  );
};

export default SearchActivitiesPage;

export const contentfulPageQuery = graphql`
  query SearchActivitiesPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      meta {
        ...contentfulPageMetaFields
      }
    }
    # Fetch all activities
    activities: allActivity(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          activityId
          title
          description
          price {
            amount
            currency
          }
          image: cardImage {
            childImageSharp {
              # created by gatsby-source-activities plugin
              image: gatsbyImageData(width: 428, aspectRatio: 1.78333, breakpoints: [428])
            }
          }
          categories
          bookingStatus
        }
      }
    }
    categories: allActivityCategory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id: categoryId
          title
        }
      }
    }
  }
`;
