import rgba from 'polished/lib/color/rgba';
import styled from 'styled-components';
import { above, below, Breakpoint, breakpoints } from '../../styles/breakpoints';
import { AppColor } from '../../styles/colors';
import Icon from '../common/icon/Icon';
import { Typography } from '../common/typography/Typography.styled';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DetailsCell = styled(Cell)`
  gap: 0.375rem;
  flex-grow: 1;
`;

const StatusCell = styled(Cell)`
  gap: 0.375rem;
  ${breakpoints.md} {
    gap: 1rem;
  }
  flex-shrink: 0;
  align-self: flex-start;

  grid-area: status;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: repeat(3, auto);

  grid-template-areas: 'icon id info status' 'icon id additionalinfoalt .' 'icon id additionalinfo .';
`;

const RowStatusIcon = styled(Icon)`
  font-size: 1rem;
  align-self: center;
  ${breakpoints.md} {
    font-size: 1.5rem;
  }
`;

type SlopeDisplayNameProps = {
  difficultyColor: string;
};

const LiftIdentifier = styled(Typography.NumberSmall)`
  display: inline-block;
  min-width: calc(1.5rem - 2px);
  height: calc(1.5rem - 2px);
  padding: 0 calc(0.5rem - 1px);
  color: ${AppColor.LeviDarkBlue};
  border: 1px solid ${AppColor.LeviDarkBlue};
  line-height: 1.1875rem;
  border-radius: 1.5rem;
  margin-top: 2px;
  font-size: 0.875rem;

  flex-shrink: 0;

  margin-right: 0.375rem;

  grid-area: id;
`;

const LiftIcon = styled.img<{ src: string }>`
  display: inline-block;
  height: calc(1.5rem - 2px);
  flex-shrink: 0;
  margin-right: 0.375rem;
  grid-area: icon;
  transform: translate(0,10%); /* Bring the icon a little bit downwards, since otherwise they're visually a little too high */
`;

const SlopeDisplayName = styled(LiftIdentifier) <SlopeDisplayNameProps>`
  color: ${AppColor.White};
  border-color: ${(props) => props.difficultyColor};
  background-color: ${(props) => props.difficultyColor};
`;

const Info = styled(Typography.BodySmall).attrs({
  as: 'span',
})`
  line-height: 1.5rem;

  & + & {
    margin-top: unset;
  }

  span + span {
    ${below(Breakpoint.md)} {
      white-space: pre;
    }
    &::before {
      ${below(Breakpoint.md)} {
        content: '\\A';
        margin: unset;
      }
      content: '•';
      display: inline;
      margin: 0 0.5625rem;
      color: ${rgba(AppColor.Neutral500, 0.3)};
    }
  }

  grid-area: info;
`;

const AdditionalInfo = styled(Info)`
  font-size: 0.75rem;
  line-height: 1.5;

  grid-area: additionalinfo;
`;

const AdditionalInfoAlt = styled(Info)`
  font-size: 0.75rem;
  line-height: 1.5;

  grid-area: additionalinfoalt;
`;

const SlopeSectionTitle = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    ${AppColor.Neutral300} calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );

  ${Typography.SubHeadingSmall} {
    position: relative;
    color: ${AppColor.LeviBlue};
    background: ${AppColor.Neutral200};
    display: inline;
    padding-right: 10px;
  }
`;

const ListTitle = styled(Typography.SubHeadingLarge)`
  text-align: center;
  margin-top: 1.5rem;
`;

const ListContainer = styled.div`
  background-color: ${AppColor.Neutral200};

  ${below(Breakpoint.md)} {
    padding: 0 var(--gutter);
  }

  ${ListTitle} + * {
    margin-top: 2rem;
  }

  ${SlopeSectionTitle} + ${List} {
    margin-top: 1rem;
  }

  ${List} + ${SlopeSectionTitle} {
    margin-top: 2rem;
  }
`;

const ListContent = styled.div`
  padding: 1.5rem 0 2.5rem;

  ${breakpoints.md} {
    padding: 1.5rem 1.5rem 2.5rem;
  }
`;

export interface ListWrapperProps {
  sections?: number;
}

const ListWrapper = styled.div<ListWrapperProps>`
  grid-column: 1 / -1;

  display: grid;

  ${({ sections }) => {
    // If only one list excists, center it
    if (sections === 1) {
      return `
        ${breakpoints.lg} {
          grid-column-start: 3;
          grid-column-end: -3;
        }
      `
    }

    // Use default 2 column listing, except when the screen size is smaller than breakpoints.lg
    return `
      ${breakpoints.lg} {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 0.5rem;
      }
    `
  }}


  grid-gap: 2rem var(--gutter);
`;

const ListLegendContainer = styled.div`
  * + & {
    margin-top: 2.5rem;
  }
  grid-column: 1 / -1;

  ${breakpoints.lg} {
    grid-column: 3 / -3;
  }
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 1px solid ${AppColor.Neutral300};
`;

const ListLegend = styled.dl`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  ${breakpoints.sm} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  grid-auto-flow: column;
  gap: 0.875rem 1.5rem;
  color: ${AppColor.Neutral600};
`;

const ListLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  dt {
    width: 1.5rem;
  }

  ${RowStatusIcon} {
    font-size: 1.5rem;
  }
  ${LiftIdentifier} {
    margin-right: unset;
  }
`;

const ListLegendDef = styled(Typography.SubLink).attrs({ as: 'dd' })``;

export default {
  List,
  Row,
  RowStatusIcon,
  DetailsCell,
  StatusCell,
  LiftIdentifier,
  LiftIcon,
  SlopeDisplayName,
  Info,
  AdditionalInfo,
  AdditionalInfoAlt,
  ListWrapper,
  ListContainer,
  ListContent,
  ListTitle,
  SlopeSectionTitle,
  ListLegendContainer,
  ListLegend,
  ListLegendItem,
  ListLegendDef,
};
